import React, { useRef, useEffect, useState } from 'react'
import { Button } from "./ui/button"
import { RefreshCw, SkipForward, CreditCard } from 'lucide-react'
import HolidayTemplate from '../templates/HolidayTemplate'
import GiftCardPresentation from './GiftCardPresentation'

export default function GameExperience({ giftCard }) {
  const canvasRef = useRef(null)
  const gameRef = useRef(null)
  const [gameState, setGameState] = useState('playing')
  const [showGiftCardPresentation, setShowGiftCardPresentation] = useState(false)
  const [gameKey, setGameKey] = useState(0) // Add a key state

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      // Always create a new game instance when the component mounts or gameKey changes
      const game = new HolidayTemplate(canvas, giftCard, handleGameComplete)
      gameRef.current = game
      game.init()
    }

    const updateGameState = () => {
      if (gameRef.current) {
        setGameState(gameRef.current.gameState)
      }
      requestAnimationFrame(updateGameState)
    }
    updateGameState()

    return () => {
      // Cleanup
      if (gameRef.current) {
        gameRef.current.cleanup() // Add a cleanup method to your game class
      }
    }
  }, [giftCard, gameKey]) // Dependencies include gameKey

  const handleGameComplete = () => {
    setShowGiftCardPresentation(true)
  }

  const restartGame = () => {
    if (gameRef.current) {
      gameRef.current.cleanup() // Cleanup the current game instance
    }
    setGameKey(prevKey => prevKey + 1) // Force re-render
    setShowGiftCardPresentation(false)
    setGameState('playing')
  }

  const skipToWin = () => {
    if (gameRef.current) {
      gameRef.current.skipToWin()
    }
  }

  const showGiftCard = () => {
    setShowGiftCardPresentation(true)
  }

  const handleBackToGame = () => {
    setShowGiftCardPresentation(false)
    setGameKey(prevKey => prevKey + 1) // Change the key to force re-render
    setGameState('playing')
  }

  if (showGiftCardPresentation) {
    return <GiftCardPresentation giftCard={giftCard} onBackToGame={handleBackToGame} />
  }

  return (
    <div className="flex flex-col h-screen">
      <header className="bg-white shadow-md p-2 flex justify-between items-center h-16">
        <div className="flex items-center">
          <img 
            src="/Logo_Rectangle.png" 
            alt="CardVenture Logo" 
            className="h-12 w-auto"
          />
        </div>
        <div className="flex space-x-2">
          {/* Removed fullscreen button */}
          {gameState === 'playing' && (
            <Button onClick={skipToWin} variant="outline" className="flex items-center text-sm">
              <SkipForward className="h-4 w-4 mr-1" />
              Skip to Gift Card
            </Button>
          )}
          {(gameState === 'won' || gameState === 'lost') && (
            <>
              <Button onClick={restartGame} variant="outline" className="flex items-center text-sm">
                <RefreshCw className="h-4 w-4 mr-1" />
                Restart Game
              </Button>
              <Button onClick={showGiftCard} variant="outline" className="flex items-center text-sm">
                <CreditCard className="h-4 w-4 mr-1" />
                View Gift Card
              </Button>
            </>
          )}
        </div>
      </header>
      <main className="flex-grow relative">
        <canvas 
          key={gameKey} // Add key to force re-render
          ref={canvasRef} 
          className="absolute top-0 left-0 w-full h-full"
        />
      </main>
    </div>
  )
}