import React from 'react';
import GameExperience from './GameExperience';

const TemplateLoader = ({ giftCard, onComplete }) => {
  console.log('TemplateLoader rendering, template name:', giftCard.template_name);
  
  if (giftCard.template_name === 'Holiday') {
    return <GameExperience giftCard={giftCard} onComplete={onComplete} />;
  }

  console.log('No matching template found');
  return null;
};

export default TemplateLoader;