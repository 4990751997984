import GameTemplate from './GameTemplate';

class HolidayTemplate extends GameTemplate {
  constructor(canvas, giftCard, onComplete) {
    super(canvas, giftCard, onComplete);
    this.gameDuration = 15000; // 15 seconds
    this.startTime = null; // Track start time
    this.obstacles = [];
    this.snowflakes = [];
    this.assetsLoaded = false;
    this.init();
  }

  init() {
    super.init();
    this.initGameVariables();
    this.loadAssets();
  }

  initGameVariables() {
    this.distance = 0;
    this.gameStartTime = null;
    this.sleighX = 50;
    this.sleighY = Math.max(this.canvas.height / 2, 100); // Set minimum height for sleigh
    this.sleighVelocityY = 0;
    this.sleighVelocityX = 2;
    this.gravity = 0.15; // Reduced gravity for slower fall
    this.lift = -3; // Reduced lift for smaller jumps
    this.maxVelocityY = 5; // Reduced maximum upward velocity
    this.minVelocityY = -5; // Reduced maximum downward velocity
    this.damping = 0.9; // Damping factor for air resistance
    this.gameSpeed = 3;
    this.gameLength = 4800; // Distance to North Pole
    this.obstacles = [];
    this.snowflakes = [];
    this.snowflakesCreated = false;
  }

  loadAssets() {
    this.sleighImg = new Image();
    this.backgroundImg = new Image();
    this.obstacleImages = {
      snowman: new Image(),
      candyCane: new Image(),
      grinch: new Image()
    };

    const promises = [
      this.loadImage(this.sleighImg, '/assets/holiday/sleigh.png'),
      this.loadImage(this.backgroundImg, '/assets/holiday/background.png'),
      this.loadImage(this.obstacleImages.snowman, '/assets/holiday/snowman.png'),
      this.loadImage(this.obstacleImages.candyCane, '/assets/holiday/candy_cane.png'),
      this.loadImage(this.obstacleImages.grinch, '/assets/holiday/grinch.png')
    ];

    Promise.all(promises)
      .then(() => {
        this.assetsLoaded = true;
        this.startGame();
      })
      .catch(error => {
        console.error("Error loading images:", error);
      });
  }

  loadImage(img, src) {
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  startGame() {
    this.gameState = 'playing';
    this.startTime = Date.now(); // Set start time
    this.gameLoop();
  }

  update(deltaTime) {
    if (this.gameState !== 'playing' || !this.assetsLoaded) return;

    this.sleighVelocityY += this.gravity; // Apply gravity
    this.sleighVelocityY *= this.damping; // Apply damping
    this.sleighY += this.sleighVelocityY; // Update position based on velocity

    // Limit maximum upward and downward velocity
    this.sleighVelocityY = Math.min(this.sleighVelocityY, this.maxVelocityY);
    this.sleighVelocityY = Math.max(this.sleighVelocityY, this.minVelocityY);

    // Ensure sleigh stays within canvas bounds
    if (this.sleighY < 0) {
      this.sleighY = 0;
      this.sleighVelocityY = 0; // Reset velocity if hitting the top
    }
    if (this.sleighY > this.canvas.height - 60) {
      this.sleighY = this.canvas.height - 60;
      this.sleighVelocityY = 0; // Reset velocity if hitting the bottom
    }

    this.distance += this.gameSpeed;

    // Check for game duration
    if (Date.now() - this.startTime >= this.gameDuration) {
      this.gameState = 'won'; // Automatically win after 15 seconds
    }

    this.obstacles.forEach((obstacle, index) => {
      obstacle.x -= this.gameSpeed;
      if (obstacle.x + obstacle.width < 0) {
        this.obstacles.splice(index, 1);
      }
    });

    if (Math.random() < 0.02) {
      this.generateObstacle();
    }

    if (this.checkCollisions()) {
      this.gameState = 'lost';
    }

    if (this.distance >= this.gameLength) {
      this.gameState = 'won';
    }
  }

  draw() {
    if (!this.assetsLoaded) return;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.drawImage(this.backgroundImg, 0, 0, this.canvas.width, this.canvas.height);

    if (this.gameState === 'playing') {
      this.ctx.drawImage(this.sleighImg, this.sleighX, this.sleighY, 100, 60);

      this.obstacles.forEach(obstacle => {
        this.ctx.drawImage(obstacle.img, obstacle.x, obstacle.y, obstacle.width, obstacle.height);
      });

      this.ctx.fillStyle = 'white';
      this.ctx.font = '20px Arial';
      const milesLeft = Math.max(0, Math.floor((this.gameLength - this.distance) / 160));
      this.ctx.fillText(`Distance to North Pole: ${milesLeft} mi`, 20, 30);
    } else if (this.gameState === 'won') {
      this.showWinScreen();
    } else if (this.gameState === 'lost') {
      this.showLoseScreen();
    }
  }

  generateObstacle() {
    const obstacleTypes = ['snowman', 'candyCane', 'grinch'];
    const type = obstacleTypes[Math.floor(Math.random() * obstacleTypes.length)];
    const obstacle = {
      x: this.canvas.width,
      y: Math.random() * (this.canvas.height - 100),
      width: 50,
      height: 100,
      type: type,
      img: this.obstacleImages[type]
    };
    this.obstacles.push(obstacle);
  }

  checkCollisions() {
    return this.obstacles.some(obstacle => {
      return (
        this.sleighX < obstacle.x + obstacle.width &&
        this.sleighX + 100 > obstacle.x &&
        this.sleighY < obstacle.y + obstacle.height &&
        this.sleighY + 60 > obstacle.y
      );
    });
  }

  showWinScreen() {
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = 'white';
    this.ctx.font = '22px Arial';
    this.ctx.textAlign = 'center';
    this.ctx.fillText(`Way to go, you won!`, this.canvas.width / 2, this.canvas.height / 2 - 50);
    this.ctx.fillText(`Now let's go claim your $${this.giftCard.amount} gift card!`, this.canvas.width / 2, this.canvas.height / 2);
  }

  showLoseScreen() {
    this.ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = 'white';
    this.ctx.font = '30px Arial';
    this.ctx.textAlign = 'center';
    this.ctx.fillText('Game Over', this.canvas.width / 2, this.canvas.height / 2);
  }

  handleCanvasClick() {
    if (this.gameState === 'playing') {
      this.sleighVelocityY = this.lift;
    } else if (this.gameState === 'won') {
      this.onComplete(this.giftCard);
    } else if (this.gameState === 'lost') {
      this.restart();
    }
  }

  onJump() {
    this.sleighVelocityY = Math.max(this.sleighVelocityY + this.lift, this.minVelocityY); // Apply lift
  }

  restart() {
    this.initGameVariables();
    this.gameState = 'playing';
    this.startGame();
  }

  gameLoop() {
    this.update();
    this.draw();
    if (this.gameState === 'playing') {
      requestAnimationFrame(() => this.gameLoop());
    }
  }

  cleanup() {
    // Cancel any ongoing animations
    cancelAnimationFrame(this.animationFrameId);
    
    // Remove event listeners
    window.removeEventListener('resize', this.resizeCanvas);
    this.canvas.removeEventListener('click', this.handleCanvasClick);
    
    // Clear the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
    // Reset game variables
    this.obstacles = [];
    this.snowflakes = [];
    this.assetsLoaded = false;
    this.gameState = 'playing';
  }
}

export default HolidayTemplate;