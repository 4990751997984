import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import html2canvas from 'html2canvas';
import { SaveIcon, CreditCardIcon, SendIcon, ArrowLeftIcon } from 'lucide-react';

const colorSchemes = {
  default: {
    background: 'from-gray-50 to-gray-100',
    card: 'from-white via-gray-50 to-gray-100',
    button: 'bg-gray-600 hover:bg-gray-700',
    text: 'text-gray-800',
    swatch: 'bg-gradient-to-br from-gray-200 to-gray-400'
  },
  purple: {
    background: 'from-purple-100 to-pink-100',
    card: 'from-purple-400 via-pink-500 to-red-500',
    button: 'bg-purple-600 hover:bg-purple-700',
    text: 'text-white',
    swatch: 'bg-gradient-to-br from-purple-400 to-pink-500'
  },
  grey: {
    background: 'from-gray-100 to-gray-200',
    card: 'from-gray-600 via-gray-700 to-black',
    button: 'bg-gray-800 hover:bg-gray-900',
    text: 'text-white',
    swatch: 'bg-gradient-to-br from-gray-600 to-black'
  },
  blue: {
    background: 'from-blue-100 to-indigo-100',
    card: 'from-blue-400 via-indigo-500 to-purple-500',
    button: 'bg-blue-600 hover:bg-blue-700',
    text: 'text-white',
    swatch: 'bg-gradient-to-br from-blue-400 to-indigo-500'
  },
  green: {
    background: 'from-green-100 to-teal-100',
    card: 'from-green-400 via-teal-500 to-blue-500',
    button: 'bg-green-600 hover:bg-green-700',
    text: 'text-white',
    swatch: 'bg-gradient-to-br from-green-400 to-teal-500'
  },
  yellow: {
    background: 'from-yellow-100 to-orange-100',
    card: 'from-yellow-400 via-orange-500 to-red-500',
    button: 'bg-yellow-600 hover:bg-yellow-700',
    text: 'text-white',
    swatch: 'bg-gradient-to-br from-yellow-400 to-orange-500'
  }
};

function GiftCardPresentation({ giftCard, onBackToGame }) {
  const [showThankYou, setShowThankYou] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [colorScheme, setColorScheme] = useState('default');

  const saveAsImage = () => {
    const element = document.getElementById('gift-card');
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'gift-card.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const addToWallet = () => {
    alert("Gift card added to your virtual wallet!");
  };

  return (
    <div className={`min-h-screen bg-gradient-to-br ${colorSchemes[colorScheme].background} flex flex-col`}>
      <header className="bg-white shadow-md p-2 flex justify-between items-center h-16">
        <div className="flex items-center">
          <img 
            src="/Logo_Rectangle.png" 
            alt="CardVenture Logo" 
            className="h-12 w-auto"
          />
        </div>
        <Button
          onClick={onBackToGame}
          variant="outline"
          className="flex items-center text-sm" // Made button smaller
        >
          {/* Changed icon to a more elegant alternative */}
          <ArrowLeftIcon className="h-4 w-4 mr-1" /> 
          Back to Game
        </Button>
      </header>

      <div className="flex-grow flex items-start justify-center p-4" style={{ paddingTop: '30px' }}> {/* Changed to items-start */}
        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-2xl p-8 max-w-2xl w-full relative overflow-hidden"
        >
          <div className="flex space-x-2 mb-4">
            {Object.keys(colorSchemes).map((scheme) => (
              <button
                key={scheme}
                onClick={() => setColorScheme(scheme)}
                className={`w-8 h-8 rounded-full ${colorSchemes[scheme].swatch} ${
                  colorScheme === scheme ? 'ring-2 ring-offset-2 ring-gray-400' : ''
                }`}
              />
            ))}
          </div>

          <div id="gift-card" className={`bg-gradient-to-r ${colorSchemes[colorScheme].card} rounded-lg p-6 mb-6 shadow-lg relative overflow-hidden`}>
            <div className="bg-white bg-opacity-20 rounded-full w-20 h-20 absolute top-4 left-4 flex items-center justify-center">
              <span className="text-2xl font-bold text-white">LOGO</span>
            </div>
            <div className={`${colorSchemes[colorScheme].text} mt-24`}>
              <h2 className="text-2xl font-semibold mb-2">To: {giftCard.recipient_name}</h2>
              <p className="mb-4">From: {giftCard.from_name}</p>
              <p className="italic mb-4">"{giftCard.custom_greeting_message}"</p>
              <div className="flex justify-between items-center">
                <span className="text-3xl font-bold">{giftCard.amount}</span>
                <span className="text-sm">{giftCard.cardNumber}</span>
              </div>
            </div>
            <div className="absolute bottom-2 right-2 text-xs text-white text-opacity-70">
              Delivered by CardVenture
            </div>
          </div>

          <div className="flex space-x-4 mb-6">
            <Button onClick={saveAsImage} className={`flex-1 ${colorSchemes[colorScheme].button} text-white`}>
              <SaveIcon className="mr-2 h-4 w-4" /> Save as Image
            </Button>
            <Button onClick={addToWallet} className={`flex-1 ${colorSchemes[colorScheme].button} text-white`}>
              <CreditCardIcon className="mr-2 h-4 w-4" /> Add to Wallet
            </Button>
          </div>

          {!showThankYou ? (
            <Button onClick={() => setShowThankYou(true)} className={`w-full ${colorSchemes[colorScheme].button} text-white`}>
              Send a Thank You
            </Button>
          ) : (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Textarea 
                placeholder="Write your thank you message here..."
                value={thankYouMessage}
                onChange={(e) => setThankYouMessage(e.target.value)}
                className="mb-4"
              />
              <Button className={`w-full ${colorSchemes[colorScheme].button} text-white`}>
                <SendIcon className="mr-2 h-4 w-4" /> Send Thank You
              </Button>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
}

export default GiftCardPresentation;