class GameTemplate {
  constructor(canvas, giftCard, onComplete) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.giftCard = giftCard;
    this.onComplete = onComplete;
    this.gameState = 'playing';
    this.score = 0;
    this.animationFrameId = null;
  }

  init() {
    this.resizeCanvas();
    this.addEventListeners();
    this.startGameLoop();
  }

  resizeCanvas() {
    this.canvas.width = this.canvas.clientWidth;
    this.canvas.height = this.canvas.clientHeight;
  }

  addEventListeners() {
    window.addEventListener('resize', () => this.resizeCanvas());
    this.canvas.addEventListener('click', () => this.handleClick());
  }

  handleClick() {
    if (this.gameState === 'playing') {
      this.onJump();
    }
  }

  startGameLoop() {
    this.gameLoop();
  }

  gameLoop() {
    this.update();
    this.draw();
    if (this.gameState === 'playing') {
      this.animationFrameId = requestAnimationFrame(() => this.gameLoop());
    }
  }

  update() {
    // To be implemented by subclasses
  }

  draw() {
    // To be implemented by subclasses
  }

  onJump() {
    // To be implemented by subclasses
  }

  skipToWin() {
    this.gameState = 'won';
    this.onComplete('won');
    cancelAnimationFrame(this.animationFrameId);
    this.draw(); // Redraw to show win state
  }

  restart() {
    cancelAnimationFrame(this.animationFrameId);
    this.gameState = 'playing';
    this.score = 0;
    this.startGameLoop();
  }

  showGiftCard() {
    this.onComplete(this.giftCard);
  }
}

export default GameTemplate;