import React, { useState, useEffect } from 'react';
import './App.css';
import './globals.css';
import TemplateLoader from './components/TemplateLoader';
import GiftCardPresentation from './components/GiftCardPresentation';
import CardNotFound from './components/CardNotFound';
import GameLoadingScreen from './components/GameLoadingScreen'; // Add this import

function App() {
  const [giftCard, setGiftCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState('loading');

  const fetchGiftCard = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const giftCardId = urlParams.get('id');
      const giftCardSecret = urlParams.get('secret');

      if (!giftCardId || !giftCardSecret) {
        throw new Error('Both cardId and secret are required');
      }

      const response = await fetch(`https://cardventure-delivery.joshua-odonoghue.workers.dev/?id=${giftCardId}&secret=${giftCardSecret}`);
      if (!response.ok) {
        throw new Error('Failed to fetch gift card');
      }

      const data = await response.json();
      // Map the API response to the expected structure
      const mappedGiftCard = {
        id: data.id,
        recipient_name: data.recipient_name,
        recipient_email: data.recipient_email,
        recipient_phone: data.recipient_phone,
        amount: data.amount,
        cardNumber: data.card_number,
        from_name: data.from_name,
        from_email: data.from_email,
        from_phone: data.from_phone,
        custom_greeting_message: data.custom_greeting_message,
        template_name: data.template_name,
        template_metadata: data.template_metadata,
        // Add any other properties needed for GiftCardPresentation
      };

      setGiftCard(mappedGiftCard);
      setCurrentStep('template');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Fetching gift card...');
    fetchGiftCard();
  }, []);

  useEffect(() => {
    console.log('Current step:', currentStep);
    console.log('Gift card:', giftCard);
  }, [currentStep, giftCard]);

  if (loading) return <GameLoadingScreen />;
  if (error) return <CardNotFound />; // Use the new component for error handling

  console.log('Rendering App component');

  return (
    <div className="App">
      {giftCard && (
        <TemplateLoader
          giftCard={giftCard}
          onComplete={() => setCurrentStep('presentation')}
        />
      )}
      {currentStep === 'presentation' && giftCard && (
        <GiftCardPresentation giftCard={giftCard} />
      )}
    </div>
  );
}

export default App;
