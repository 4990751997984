import React from 'react'
import { motion } from 'framer-motion'
import { Button } from "./ui/button"
import { HomeIcon, RefreshCcwIcon } from 'lucide-react'

export default function CardNotFound() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-50 to-yellow-100 flex flex-col items-center justify-center p-4">
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-2xl p-8 max-w-md w-full text-center"
      >
        <img 
          src="/Logo_Rectangle.png" 
          alt="CardVenture Logo" 
          className="h-16 mx-auto mb-6"
        />
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Oops! Gift Card Not Found</h1>
        <p className="text-gray-600 mb-6">
          We couldn't find the gift card you're looking for. It seems the URL might be incomplete or incorrect.
        </p>
        <p className="text-gray-600 mb-8">
          Please reach out to the person who sent you this gift card and ask them to provide you with the full, correct URL.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Button className="bg-yellow-400 text-black hover:bg-yellow-500">
            <HomeIcon className="mr-2 h-4 w-4" /> Go to Homepage
          </Button>
          <Button className="bg-gray-200 text-gray-800 hover:bg-gray-300">
            <RefreshCcwIcon className="mr-2 h-4 w-4" /> Try Again
          </Button>
        </div>
      </motion.div>
      <p className="mt-8 text-sm text-gray-500">
        © {new Date().getFullYear()} CardVenture. All rights reserved.
      </p>
    </div>
  )
}