// Remove TypeScript interface
// interface LoadingSpinnerProps {
//   size?: 'small' | 'medium' | 'large'
//   className?: string
// }

// Update function signature to remove TypeScript types
export default function LoadingSpinner({ size = 'medium', className = '' }) {
    const sizeClasses = {
      small: 'h-8 w-8 border-2',
      medium: 'h-16 w-16 border-4',
      large: 'h-32 w-32 border-8'
    }
  
    return (
      <div className={`flex items-center justify-center ${className}`}>
        <div
          className={`animate-spin rounded-full border-t-yellow-500 border-r-yellow-500 border-b-yellow-500 border-l-transparent ${sizeClasses[size]}`}
          role="status"
          aria-label="Loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }