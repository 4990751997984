import React from 'react'
import LoadingSpinner from './ui/LoadingSpinner'; // Ensure this path is correct

export default function GameLoadingScreen() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-50 to-yellow-100 flex flex-col items-center justify-center p-4">
      <img 
        src="/Logo_Rectangle.png" 
        alt="CardVenture Logo" 
        className="h-16 mb-8"
      />
      <LoadingSpinner size="small" className="mb-4" />
      <p className="text-xl font-semibold text-gray-800">Loading your adventure...</p>
    </div>
  )
}